import React from 'react'
import { Voyager } from 'graphql-voyager'

import './App.css'

const introspectionProvider = query => {
  return fetch(`https://api2.smartcfo.cl/graphql`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  }).then(response => response.json().then(object => object))
}

const App = () => {
  return (
    <Voyager
      introspection={introspectionProvider}
      workerURI={`${process.env.PUBLIC_URL}/voyager.worker.js`}
    />
  )
}

export default App
